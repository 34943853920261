<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('irrigation_config.pump_capacity') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
          <b-col lg="6" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('irrigation_config.organization')"
              label-for="org_id"
            >
              <b-form-select
                plain
                id="org_id"
                v-model="search.org_id"
                :options="orgList"
                >
                <template v-slot:first>
                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('irrigation_config.scheme_typel')"
              label-for="master_scheme_type_id"
            >
              <b-form-select
                plain
                id="master_scheme_type_id"
                v-model="search.master_scheme_type_id"
                :options="MasterSchemeTypeList"
                >
                <template v-slot:first>
                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('irrigation_config.pump_capacity')"
              label-for="pump_capacity"
            >
              <b-form-input
                id="pump_capacity"
                v-model="search.pump_capacity"
                ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="6" sm="12" xs="12">
            <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('irrigation_config.pump_capacity') + ' ' + $t('globalTrans.list') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button class="btn_add_new" v-b-modal.modal-4 @click="resetId">
              <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(sub_scheme_type_name)="data">
                      {{ data.item.sub_scheme_type_name }}
                    </template>
                    <template v-slot:cell(capacity)="data">
                      {{ $n(data.item.capacity) }}
                    </template>
                    <template v-slot:cell(status)="data">
                      <!-- {{ data.item.status ? $t('globalTrans.inactive') : $t('globalTrans.active') }} -->
                       <span class="badge badge-danger" v-if="data.item.status">{{ $t('globalTrans.inactive') }}</span>
                       <span class="badge badge-success" v-else>{{ $t('globalTrans.active') }}</span>
                    </template>
                    <template v-slot:cell(action)="data">
                      <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-4 @click="edit(data.item)"><i class="ri-ball-pen-fill"></i></a>
                      <a href="javascript:" class="btn_table_action table_action_status" title="Active" v-if="data.item.status === 0" @click="remove(data.item)"><i class="fas fa-toggle-on"></i></a>
                      <a href="javascript:" class="btn_table_action table_action_toggle" title="Inactive" v-else @click="remove(data.item)"><i class="fas fa-toggle-off"></i></a>
                    </template>
                  </b-table>
                  <b-pagination
                    align="center"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <FormV :id="editItemId" :key="editItemId"/>
      </p>
    </b-modal>
  </b-container>
</template>
<script>
import FormV from './Form'
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { pumpCapacityList, pumpCapacityToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import { mapGetters } from 'vuex'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    FormV
  },
  data () {
    return {
      search: {
        org_id: 0,
        master_scheme_type_id: 0,
        pump_capacity: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    orgList () {
      return this.$store.state.commonObj.organizationProfileList.filter(scheme => scheme.status === 0)
    },
    MasterSchemeTypeList () {
      return this.$store.state.IrriConfig.commonObj.schemeTypeList.filter(scheme => scheme.status === 0)
    },
    formTitle () {
      return (this.editItemId === 0) ? this.$t('irrigation_config.pump_capacity_list') + ' ' + this.$t('globalTrans.entry') : this.$t('irrigation_config.pump_capacity') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('org_pro.organization'), class: 'text-left' },
          { label: this.$t('irrigation_config.scheme_typel'), class: 'text-center' },
          { label: this.$t('irrigation_config.pump_capacity'), class: 'text-center' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'org_name_bn' },
          { key: 'scheme_type_name_bn' },
          { key: 'capacity' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'org_name' },
          { key: 'scheme_type_name' },
          { key: 'capacity' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    remove (item) {
      this.changeStatus(irriSchemeServiceBaseUrl, pumpCapacityToggleStatus, item, 'irrigation', 'schemeTypeList')
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(irriSchemeServiceBaseUrl, pumpCapacityList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', this.dataList(response.data.data))
          this.paginationData(response.data)
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    dataList (data) {
      const orgList = this.$store.state.orgList
      let tmpData = {}
      const listData = data.map(item => {
        tmpData = orgList.find(orgItem => orgItem.value === item.org_id)
        const orgData = { org_name: tmpData.text_en, org_name_bn: tmpData.text_bn }
        return Object.assign({}, item, orgData)
      })
      return listData
    }
  }
}
</script>
